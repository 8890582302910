import React from "react"
import Img from "gatsby-image"
import ReactPlayer from "react-player/lazy"
import LazyImage from "../LazyImage"
import { buildImageObj } from "../../lib/helpers"
import { imageUrlFor } from "../../lib/image-url"

const ImageList = ({ images }) => {
  if (!images) return null

  return (
    <div>
      {images.map((image) => {
        switch (image.__typename) {
          case "SanityMainImage":
            return (
              <div key={image.asset._id} className="mb-8">
                <Img alt={image.alt} fluid={image.asset.fluid}></Img>
              </div>
            )
            break
          case "SanityVideoEmbed":
            return (
              <div key={image.url} className="mb-8 flex justify-center">
                <div className="relative w-full" style={{ paddingBottom: "56.25%" }}>
                  <ReactPlayer
                    width="100%"
                    height="100%"
                    light
                    controls
                    url={image.url}
                    className="inset-0 absolute"
                  />
                </div>
              </div>
            )
            break
          default:
            console.warn(`Cannot find component with type ${image.__typename}`)
            break
        }
      })}
    </div>
  )
}

export default ImageList
