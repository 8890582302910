import React from "react"
import { graphql } from "gatsby"

import GraphQLErrorList from "../components/graphql-error-list"
import SEO from "../components/seo"
import CaseView from "../components/CaseView"
import Layout from "../containers/layout"

export const query = graphql`
  query CaseQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      primaryColor {
        hex
      }
      secondaryColor {
        hex
      }
      title
      openGraph {
        title
        description
        image {
          ...SanityImage
        }
      }
      navMenu {
        ...NavMenu
      }
    }
    project: sanityProject(id: { eq: $id }) {
      ...CaseInfo
      slug {
        current
      }
      title
    }
  }
`

const Page = (props) => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const site = (data || {}).site

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }
  const menuItems = site.navMenu && (site.navMenu.items || [])
  const project = data.project
  const pageTitle = `${site.title} - ${project.title} `

  return (
    <Layout navMenuItems={menuItems} textWhite={true}>
      <SEO
        title={pageTitle}
        description={project.openGraph?.description || site.openGraph?.description}
        image={project.mainImage}
        bodyAttr={{
          class: "leading-normal tracking-normal",
        }}
      />
      <div className="lg:pt-24">
        <CaseView {...project} />
      </div>
    </Layout>
  )
}

export default Page
