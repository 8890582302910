import React from "react"
import ImageList from "../ImageList"
import PortableText from "../portableText"
import styles from "./caseview.module.css"
import { cn } from "../../lib/helpers"

const CaseView = ({ title, mainImage, images, brand, _rawBody, ...rest }) => {
  const handleBack = () => window.history && window.history.back()

  return (
    <section className="flex flex-wrap px-4 flex-col md:flex-row pt-4 md:pt-0 md:px-10 ">
      <aside className="md:w-full lg:w-2/12">
        <button onClick={handleBack} className="text-xs flex flex-wrap items-center">
          <span className="mr-2">
            <svg
              width="30"
              height="30"
              viewBox="0 0 471 472"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.28932 236L76 165.289M5.28932 236L76 306.711M5.28932 236H467.5"
                stroke="black"
                strokeWidth="7"
              />
            </svg>
          </span>
          <span>Back to overview</span>
        </button>
      </aside>
      {images.length > 0 && (
        <div className="md:w-2/3 md:pr-5 lg:px-5 lg:w-7/12 order-last md:order-none">
          <ImageList images={images} />
        </div>
      )}
      <article className="md:w-1/3 md:pl-5 lg:w-3/12 lg:px-5">
        <div className={cn("sticky", styles.caseDescription)}>
          {brand?.name && (
            <p className={cn("uppercase text-sm text-grey-600 mb-1")}>{brand.name}</p>
          )}
          {title && <h1 className={cn("text-4xl font-light mb-5")}>{title}</h1>}
          {_rawBody && <PortableText blocks={_rawBody}></PortableText>}
        </div>
      </article>
    </section>
  )
}

export default CaseView
